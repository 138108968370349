<template>
<div>
  <loading-overlay :active="isLoadingGeneral" :is-full-page="true" loader="bars" />
  <CModalExtended
    :title="$t('label.nuevo')+' '+$t('label.port')"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <form @submit.stop.prevent="submit" class="company-form">
      <CRow>
        <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="4"> 
                  <div class="form-group form-row mb-5" rol="group">
                    <div class="col-sm-12 input-group-sm alto">
                      <gmaps-map 
                        :options="mapOptions"
                        @mounted="ready"
                        @click="setMarker"
                      >
                        <gmaps-marker 
                          :options="markerOptions"
                          @move="handleMarkerPosition"
                        />
                      </gmaps-map>
                    </div>
                  </div>
                </CCol>
              
                <CCol sm="8">
                  <div class="form-group form-row" rol="group">
                    <label class="required text-right col-form-label col-sm-4 col-form-label-sm" for="pais">{{$t('label.country')}}</label>
                    <div class="col-sm-8 input-group-sm">
                      <v-select 
                        :filter="fuseSearchPais"
                        :options="paisOptions"
                        label="CountryName"
                        :placeholder="$t('label.select')"
                        v-model="pais"
                        :disabled="isPaisesEmpty"
                        :class="isPaisesValid(portVal)"
                      />
                      <div :class="isPaisValid">
                        {{$t('label.required')}}
                      </div>
                    </div>
                  </div>
                  <CInput
                    size="sm"
                    :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                    v-uppercase
                    maxlength="100"
                    :label="$t('label.name')"
                    :placeholder="$t('label.portName')"
                    addLabelClasses="text-right required"
                    v-model.trim="$v.puerto.PortName.$model"
                    :invalid-feedback="errorMessage($v.puerto.PortName)"  
                    :is-valid="hasError($v.puerto.PortName)"
                  />
                  <div class="position-relative">
                    <div class="form-group form-row" rol="group">
                      <label class="text-right col-form-label col-sm-4 col-form-label-sm" for="pais">{{$t('label.state')}}</label>
                      <div class="col-sm-8 input-group-sm">
                        <v-select
                          v-model="estado"
                          label="StateName"
                          :options="estadoOptions"
                          :filter="fuseSearchEstado"
                          :disabled="isEstadosEmpty"
                          :placeholder="$t('label.select')"
                          :class="isEstadosValid(portVal)"
                        />
                      </div>
                    </div>
                    <loading element="select" v-show="isLoadingEstado"/>
                    <!--
                    <CElementCover 
                      :opacity="0.8"
                      v-show="isLoadingEstado"
                    >
                      <label class="d-inline">{{$t('label.load')}}... </label>
                      <CSpinner size="sm" />
                    </CElementCover>
                    -->
                  </div>
                  <CInput
                    v-model.trim="$v.puerto.PortCode.$model"
                    :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                    v-uppercase
                    :invalid-feedback="errorMessage($v.puerto.PortCode)"  
                    :is-valid="hasError($v.puerto.PortCode)"
                    size="sm"
                    :label="$t('label.code')"
                    :placeholder="$t('label.code')"
                    addLabelClasses="text-right required"
                    maxlength="3"
                  />
                  <div class="form-group form-row" rol="group">
                    <label class="text-right col-form-label col-sm-4 col-form-label-sm" for="pais">
                      {{$t('label.geolocation')}}
                    </label>
                    <div class="col-sm-8 input-group-sm">
                      <input
                          type="text"
                          id="autocomplete"
                          ref="autocomplete"
                          :placeholder="$t('label.search')"
                          class="form-control"
                        />          
                    </div>
                  </div>
                  <CInput
                    size="sm"
                    :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                    maxlength="15"
                    :label="$t('label.latitude')"
                    addLabelClasses="text-right"
                    :placeholder="placeholderPos.lat"
                    v-model.trim="$v.puerto.Latitude.$model"
                    :invalid-feedback="errorMessage($v.puerto.Latitude)"  
                    :is-valid="hasError($v.puerto.Latitude)"
                  />
                  <CInput
                    size="sm"
                    :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                    maxlength="15"
                    :label="$t('label.longitude')"
                    addLabelClasses="text-right"
                    :placeholder="placeholderPos.lng"
                    v-model.trim="$v.puerto.Longitude.$model"
                    :invalid-feedback="errorMessage($v.puerto.Longitude)"  
                    :is-valid="hasError($v.puerto.Longitude)"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>         
    </form>
    <template #footer>
      <CButton
        color="add"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="submit"
      >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
<!--
        <div v-if="!isSubmit">
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </div>
        <div v-if="isSubmit">
          <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1">{{$t('button.accept')}}</span>
        </div>
        -->
      </CButton>
      <CButton
        color="wipe"
        :disabled="isSubmit"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
      </CButton>
    </template>
  </CModalExtended>
</div>
</template>

<script>
import PuertoValidations from '@/_validations/puerto/PuertoFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import PuertoMixin from '@/_mixins/puerto';
import ModalMixin from '@/_mixins/modal';

import MapsMixin from './MapsMixin';
import { isLatitude, isLongitude } from '@/_validations/funciones';
import { gmapsMap, gmapsMarker } from 'x5-gmaps';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoadingGeneral: false,
    isLoadingEstado: false,
    pais: null,
    paises: [],
    estado: null,
    estados: [],
    puerto: {
      CountryId: '',
      CountryCode: 'VE',
      StateId: '',
      PortName: '',
      PortCode: '',
      Latitude: '',
      Longitude: ''
    },
    portVal: false,
  }
}

//Methods
function submit() {
  try {
    this.isSubmit = true;
    this.$v.puerto.$touch();

    if (this.$v.puerto.$error) {
      this.isSubmit = false;
      this.portVal = true;
      throw this.$t('label.errorsPleaseCheck');
    }

    let PortJson = this.formatedPuerto();

    this.$http.post('Port-insert', PortJson, { root: 'PortJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function formatedPuerto(){
  if(this.puerto.Latitude == '' && this.puerto.Longitude == ''){
    this.puerto.Latitude = this.placeholderPos.lat!=0?this.placeholderPos.lat:'';
    this.puerto.Longitude = this.placeholderPos.lng!=0?this.placeholderPos.lng:'';
  }

  return {
    CountryId: this.pais.CountryId,
    CountryCode: this.pais.IsoCodeAlpha2,
    StateId: this.estado?this.estado.StateId:'',
    PortName: this.puerto.PortName,
    PortCode: this.puerto.PortCode,
    Latitude: `${this.puerto.Latitude}`,
    Longitude: `${this.puerto.Longitude}`
  }
}
function toggle(newVal) {
  if(newVal){
    this.getPaises()
    this.puerto.Latitude = '';
    this.puerto.Longitude = '';
  }else
    this.resetForm();

  this.$v.$reset();
  this.modalActive = newVal;
}
function resetForm() {
  this.puerto.CountryId = '';
  this.puerto.CountryCode = '';
  this.puerto.StateId = '';
  this.puerto.PortName = '';
  this.puerto.PortCode = '';
  this.puerto.Latitude = '';
  this.puerto.Longitude = '';
  this.pais = null;
  this.estado = null;
  this.paises = [];
  this.estados = [];
  this.portVal = false;

  this.placeholderPos.lat = 0;
  this.placeholderPos.lng = 0;
  this.mapOptions = { ...this.mapOptions, center: { lat: this.initLatitud, lng: this.initLongitud } };
  this.markerOptions.position = {  lat: this.initLatitud, lng: this.initLongitud };

  this.$v.$reset();
}

function isPaisesValid(portVal) {
  if(this.portVal === true){ 
    return (this.pais == null || this.pais == "") ? 'no-valido' : 'valido';
  }
}

function isEstadosValid(portVal) {
  if(this.portVal === true){ 
    return 'valido';
  }
}

//Computeds:
function latitud() {
  return this.puerto.Latitude;
}
function longitud() {
  return this.puerto.Longitude;
}

export default {
  name: 'add-puerto-modal',
  mixins: [
    PuertoMixin, 
    MapsMixin,
    ModalMixin,
  ],
  components:{ gmapsMap, gmapsMarker },
  props: {
    modal: Boolean
  },
  data,
  validations: PuertoValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    formatedPuerto,
    isPaisesValid,
    isEstadosValid,
  },
  computed: {
    latitud,
    longitud
  },
  watch: {
    pais: function(newPais) {
      if(newPais){
        this.estado = null;
        this.getEstados(newPais.CountryId);
        this.portVal = true;
        if(newPais.Latitude && newPais.Longitude){
          this.updatePosition(Number.parseFloat(newPais.Latitude), Number.parseFloat(newPais.Longitude));
          this.handleMarkerPosition({
            lat: Number.parseFloat(newPais.Latitude),
            lng: Number.parseFloat(newPais.Longitude)
          });
        }
      }else{
        this.estado = null;
        this.estados = [];
      }
    },
    latitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.puerto.Latitude) && isLongitude(this.puerto.Longitude)){
        this.updatePosition(Number.parseFloat(this.puerto.Latitude), Number.parseFloat(this.puerto.Longitude));
      }
    },
    longitud: function(newVal) {
      this.placeholderPos.lat = 0;
      this.placeholderPos.lng = 0;
      if(isLatitude(this.puerto.Latitude) && isLongitude(this.puerto.Longitude)){
        this.updatePosition(Number.parseFloat(this.puerto.Latitude), Number.parseFloat(this.puerto.Longitude));
      }
    }
  }
}
</script>
<style lang="scss" scope>
.invalid {
  display: block;
}
.alto {
  height: 300px;
}
.form-margin {
  margin-bottom: 0.5rem;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>